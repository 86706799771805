import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useToast } from '../../hooks/useToast';

const Header = (props) => {
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const asideVisibility = props.asideVisibility;
	const setAsideVisibility = props.setAsideVisibility;

	const setAsideType = props.setAsideType;

	const setBookingSelected = props.setBookingSelected;

	const toast = useToast();

	const resetHomeDisplay = () => {
		setAsideType('classic');
		setBookingSelected(0);
	}

	const toogleAsideVisibility = () => {
		if (asideVisibility === "visible") return setAsideVisibility("hidden");
		setAsideVisibility("visible");
	}

	const logOut = (e) => {
		sessionStorage.clear();
		return navigate('/login');
	}

	return (
		<header onClick={() => resetHomeDisplay()} className="flex-row-between">
			<img 
				src="pictures/sidebarIcon.svg" 
				alt="Toogle sidebar" 
				className="hamburgerMenu-icon" 
				onClick={() => toogleAsideVisibility()}
			/>
			<ul className="flex-row-around tabs">
				<li 
					className={pathname === '/' || pathname.startsWith('/home') ? 'selected' : ''} onClick={() => navigate("/home")}>
					Home
				</li>
				<li 
					className={pathname.startsWith('/calendar') ? 'selected' : ''} onClick={() => navigate("/calendar")}>
					Calendar
				</li>
				<li 
					className={pathname.startsWith('/seatingPlan') ? 'selected' : ''} onClick={() => navigate("/seatingPlan")}>
					Seating plan
				</li>
				<li 
					className={pathname.startsWith('/settings') ? 'selected' : ''} onClick={() => {
						if (sessionStorage.getItem("settings_access") === "true") { 
							navigate("/settings");
						} else {
							toast("warning", "Warning ! You have not access to this page...")
						}}
					}>
					Settings
				</li>
			</ul>
			<div className="log-out-button" onClick={logOut}>
				{sessionStorage.getItem("pseudo")}
			</div>	
		</header>
	)
}

export default Header;