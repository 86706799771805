import Axios from 'axios';

const get_groups = () => {
	return new Promise((resolve, reject) => {
		Axios.get(`${process.env.REACT_APP_API_ADRESS}/api/bookings/groups`, {
			headers: {
				token: sessionStorage.getItem("id")
			}
		})
		.then((res) => {
			if (process.env.REACT_APP_DISPLAY_LOGS_2) console.log("[Get current pos]: success ->", res.data.value);

			return resolve({
				state: "OK",
				value: res.data.value
			});
		})
		.catch(res => {
			if (process.env.REACT_APP_DISPLAY_LOGS_0) console.log("[Get current pos]: failed ->", res.data.value);
			// insertLog("Get current POS", "NULL", sessionStorage.getItem("id"), "Error", res.data.value);
			return reject({
				state: "error",
				value: "An error occured while trying to load some data... Please relaod the page or contact an administrator."
			});
		});
	});
}

export { get_groups };