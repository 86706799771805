import Axios from 'axios';

const put_posSettings = (data, settingID) => {
	console.log({
		services: data.pos.map((object) => object.services.join('/')).join(','),
		coverts: data.pos.map((object) => object.coverts.join('/')).join(','),
	})
	return new Promise((resolve, reject) => {
		let date = '';
		
		if (data.setting.periodActivation === 'day') date = ':' + data.setting.day;
		if (data.setting.periodActivation === 'period') date = ':' + data.setting.period0 + '/' + data.setting.period1;

		Axios.put(`${process.env.REACT_APP_API_ADRESS}/api/posSettings`, {
			id: settingID,
			name: data.name,
			period: data.setting.periodActivation + date,
			pos: data.pos.map((object) => object.name).join(','),
			open: data.pos.map((object) => object.isOpen).join(','),
			groupSize: data.setting.groupSize,
			services: data.pos.map((object) => object.services.join('/')).join(','),
			coverts: data.pos.map((object) => object.coverts.join('/')).join(','),
		}, {
			headers: {
				token: sessionStorage.getItem("id")
			}
		})
		.then(res => {			
			return resolve({
				state: "OK",
				value: "POS settings successfuly updated."
			});
		})
		.catch(res => {
			if (res.state === 'IDT') {
				if (res.value === 'groupSize') {
					return reject({
						state: "error",
						value: "Goup size must be a integer greater than 0."
					})
				}
			}
			
			if (res.state === "MD") {
				return reject({
					state: "error",
					value: `The ${res.value} of the setting seems to miss. Please add one.`
				});
			}
			if (res.state === "TSD") {
				return reject({
					state: "error",
					value: `The ${res.value} must be filled.`
				});
			}
			if (res.state === "TLD") {
				return reject({
					state: "error",
					value: `The ${res.value} must be less than 45 characters.`
				});
			}
			if (res.state === "IDT") {
				return reject({
					state: "error",
					value: `The group size must be na integer.`
				});
			}
			if (res.state === "<0") {
				return reject({
					state: "error",
					value: `The group size greater or equal to 1.`
				});
			}

			if (res.state === "auth") {
				return reject({
					state: "error",
					value: `You are not connected.`
				});
			}
		});
	});
}

export { put_posSettings };