import Axios from 'axios';

const get_settingByID = (id, data, meta) => {
	return new Promise((resolve, reject) => {
		Axios.get(`${process.env.REACT_APP_API_ADRESS}/api/posSettings/byID`, {
			headers: {
				token: sessionStorage.getItem("id")
			},
			params: {
				id: id,
				getData: data,
				getMeta: meta
			}
		})
		.then((res) => resolve({
			state: "OK",
			value: res.data.value
		}))
		.catch(res => reject({
			state: "error",
			value: "An error occured while trying to load data... Please retry or contact an administrator."
		}));
	});
}

export { get_settingByID };