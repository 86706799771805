import Axios from 'axios';

const postUser = (data) => {
	return new Promise((resolve, reject) => {
		if (data.password !== data.password2) return reject({
			state: "warning",
			value: "The two passwords must be equal."
		});

		Axios.post(`${process.env.REACT_APP_API_ADRESS}/api/users`, {
			data: {
				pseudo: data.pseudo,
				password: data.password,
				role: data.role
			},
			id: sessionStorage.getItem('id')
		})
		.then(res => {
			console.log(res)
			return resolve({
				state: "OK",
				value: "User successfuly created"
			})
		})
		.catch(error => {
			if (error.response) {
				if (error.response.status === 401) {
					return reject({
						state: "warning",
						value: "It seems that you're not connected. Please log in before any action."
					});
				}
				if (error.response.status === 422) {
					if (error.response.data.state === "MD") {
						return reject({
							state: "warning",
							value: "One or more fields seem not fully filled. If it's the case, please contact an administartor."
						});
					}
					if (error.response.data.state === "IDV") { // The only field that might return IDV is role
						return reject({
							state: "warning",
							value: "The value of the family user does not correspond to the ones selected. Please reload the page or contact an administartor."
						});
					}
				} 
			}

			return reject({
				state: "error",
				value: "An error occured. Please try to reload the page."
			});

			// return reject({
			// 	state: "error",
			// 	value: res.value
			// })
		});
	});
}

export { postUser };	