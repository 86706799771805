import React, { useState, useEffect, useRef } from 'react';
import { useFormContext } from "react-hook-form";
import { useOutletContext } from "react-router-dom";

import { Context } from '../../modules/context';

import './homeStyle.css';

import ClientStatistic from './csc';
import Searchbar from './searchbar';
import BookingsTable from './bookingsTable';

const Home = (props) => {
	const {
		setAsideType,
		setBookingSelected,
		reloadBookings,
		setReloadBookings,
		bookingSelected,
		container
	} = useOutletContext();

	const { setValue, getValues } = useFormContext();

	const [mouseCoordinates, setMouseCoordinates] = useState({x: NaN, y: NaN});
	const [startingPosYCSC, setStartingPosYCSC] = useState(0);

	// Searchbar useStates
	const [searchInput, setSearchInput] = useState({
		words: '',
		pos: ''
	});
	const [isSearching, setIsSearching] = useState(false);

	const fillBookingAside = () => {
		setValue("posName", 'pos');
		setValue("firstName", '');
		setValue("lastName", '');
		setValue("date", '');
		setValue("time", '');
		setValue("coverts", '');
		setValue("intExt", 'ext');
		setValue("contact", '');
		setValue("bookingNotes", '');
	}

	const handleScroll = () => {
		if (!container.current) return;

		setMouseCoordinates({
			x: mouseCoordinates.x,
			y: startingPosYCSC - container.current?.scrollTop
		});
	}

	useEffect(() => {
		const handleWindowMouseMove = (event) => {
			if (!event.target.closest(".bookingRow") ||
				event.target.closest('.bookingRow_state') ||
				event.target.closest("#csc_container")) return;

			setMouseCoordinates({
				x: event.clientX - (255 / 2),
				y: (
					(window.innerHeight - event.clientY - 10 > 330) ? 
					event.clientY - 10 : 
					event.clientY - 10 - 325 
				),	
			});
			setStartingPosYCSC(
				(window.innerHeight - event.clientY - 10 > 330) ? 
				event.clientY - 10 : 
				event.clientY - 10 - 325 
			);
		};

		window.addEventListener('mouseup', handleWindowMouseMove);
		return () => {
			window.removeEventListener('mouseup', handleWindowMouseMove);
		};
	}, []);

	useEffect(() => {
		if (!container.current) return;

		container.current?.addEventListener('scroll', handleScroll);
		return () => {
			container.current?.removeEventListener('scroll', handleScroll);
		};
	}, [mouseCoordinates, startingPosYCSC]);

	const canUserInsertBooking = sessionStorage.getItem("insert_bookings") === "true";
	const canUserSeeStats = sessionStorage.getItem("access_statistics") === "true";

	return (
		<Context 
			reloadBookings={reloadBookings}
			setReloadBookings={setReloadBookings}

			setBookingSelected={setBookingSelected}
			bookingSelected = {bookingSelected}

			setAsideType={setAsideType}

			setValue = {setValue}

			isSearching={isSearching}
			setIsSearching={setIsSearching}
			
			searchInput={searchInput}
		>
			<Searchbar setSearchInput={setSearchInput} />
			{
				canUserInsertBooking &&
				<button 
					id="addBooking_button"
					title="Add new client booking"
					onClick={(event) => setAsideType("ADD") & event.stopPropagation() & setBookingSelected(0) & fillBookingAside()}
					disabled={sessionStorage.getItem("insert_bookings") !== "true" ? "disabled" : ''}
				>
					ADD +
				</button>
			}
			<BookingsTable setBookingSelected={setBookingSelected} />
			{
				bookingSelected && canUserSeeStats && false ?
				<ClientStatistic 
					mouseCoordinates={mouseCoordinates}
					firstName={getValues("firstName")}
					lastName={getValues("lastName")}
				/> : null
			}
		</Context>
	);
}

export default Home;