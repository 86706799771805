import React, { useState, useEffect } from 'react';

import { useToast } from '../../hooks/useToast';

import { get_groups } from '../../requests/settings/get_groups';

// import insertLog from '../logs/insertLog';

import BookingLine from './assets/bookingLine';

const ConfirmBookings = (props) => {
	const [bookings, setBookings] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const [reloadTimes, setReloadTimes] = useState(0);

	const toast = useToast();

	useEffect(() => {
		setIsLoading(true);
		get_groups()
		.then((res) => {
			setIsLoading(false);

			setBookings(res.value);
		})
		.catch(res => toast(res.state, res.value));
	}, [reloadTimes]);

	return (
		<>
			<div className="flex-row-around settings-box__header">
				<h4>Confirm booking</h4>
			</div>
			<div className="flex-col-around settings-box__content" style={{padding: "5px", margin: "auto"}}>
				{ !isLoading &&
					Object.entries(bookings).map(([key, rows]) => {
						return (
							<>
								<h4 style={{textAlign: "center"}}>{key === "true" ? "Accepted bookings" : "Bookings not accepted yet"}</h4>
								{
									rows !== [] ?
										rows.map((object) => {
											return (
												<BookingLine
													id = 			{object.id}
													firstName =		{object.first_name}
													lastName =  	{object.last_name}
													place = 		{object.place}
													date = 			{object.booking_date}
													time = 			{object.time}
													coverts = 		{object.coverts}
													intExt = 		{object.internal}
													is_accepted = 	{key}

													toast = {toast}
													reloadTimes = {reloadTimes}
													setReloadTimes = {setReloadTimes}
												/>
											)
										}
									) : null
								}
							</>
						)
					})
				}
			</div>
			{ isLoading &&
				<div className="grid-center loader" style={{position: 'relative', zIndex: 1}}>
					<div>
						<img src="pictures/loadingIcon.svg" alt="loader"/>
						<h4>Loading settings...</h4>
					</div>
				</div>
			}
		</>
	)
}

export default ConfirmBookings;