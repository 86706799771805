import React from 'react';
import { useState, useEffect } from 'react';
import { useFormContext } from "react-hook-form";

import { CUDBookings } from '../../requests/bookings/CUDBookings';

import Axios from 'axios';
import { get_currentPOS } from '../../requests/settings/get_currentPOS';

import { useToast } from '../../hooks/useToast';

// import insertLog from '../logs/insertLog';

const BookingAside = (props) => {
	const [posList, setPosList] = useState([]);

	const {
		handleSubmit,
		register,
		setError,
		getValues,
		setValue,
		clearErrors,
		formState: { errors }
	} = useFormContext();

	const type 			= props.asideType;	
	const setAsideType	= props.setAsideType;

	const setBookingSelected = props.setBookingSelected;
	
	const reloadBookings = props.reloadBookings;
	const setReloadBookings = props.setReloadBookings;

	const toast = useToast();

	const onSubmit = (type, data) => {
		CUDBookings(type, data)
		.then((res) => {
			setValue("oldCoverts", getValues("coverts"));
			setValue("oldDatetime", getValues("oldDatetime"));

			setReloadBookings(reloadBookings + 1);
			setBookingSelected(0);
			setAsideType("classic");

			toast("OK", res.value);
		})
		.catch(res => {
			toast(res.state, res.value)});
	}

	useEffect(() => {
		get_currentPOS()
		.then((res) => {
			setPosList(res.value)
		})
		.catch(res => toast(res.state, res.value));
	}, []);

	return (
		<>
			<h1>Manage booking {sessionStorage.getItem("delete_bookings") === "true" && type !== "ADD" ? <img src="pictures/trash.svg" alt="Delete booking" onClick={() => onSubmit("delete", {
				id: getValues("id"), 
				firstName: getValues("firstName"),
				lastName: getValues("lastName"),
				dateTime: getValues("date") + " " + getValues("time"),
			})}/> : ''}</h1>
			<form id="bookingForm" className="flex-col" onSubmit={handleSubmit((data) => onSubmit(type === "ADD" ? "insert" : "update", data))}>
				<div className="separator"></div> {/* First information */}
				<div className="flex-col">
					<select 
						id="posName" name="posName"
						{...register("posName", {required: true})}
						aria-invalid={errors.posName ? "true" : "false"}
						onChange={(event) => {
							if (event.target.value === "pos") {
								setError("posName", {type: "noPOS"})
							}
						}}
					>
						<option value="pos" disabled="disabled">Point Of Service</option>
						{	posList.length !== 0 &&
							posList.map((object, i) => {
								return <option key={object + '-' + i} value={object}>{object}</option>
							})
						}
					</select>
					{errors.posName?.type === "noPOS" && (
						<label role="alert" className="fieldError">POS field has to be choosen</label>
					)}

					<div className="flex-row-around">
						{/*  min={fullDate.current}  */}
						<input type="date" id="date" name="date"
							{...register("date", {required: true})}
							aria-invalid={errors.date ? "true" : "false"}
						/>
						<input type="time" id="time" name="time" 
							{...register("time", {required: true})}
							aria-invalid={errors.time ? "true" : "false"}
						/>
					</div>
					{errors.date?.type === "required" && (
						<label role="alert" className="fieldError">Date field is required</label>
					)}
					{errors.time?.type === "required" && (
						<label role="alert" className="fieldError">Time field is required</label>
					)}


					<input type="text" id="firstName" name="firstName" placeholder="First name" 
						{...register("firstName", {required: true, maxLength: 15})}
						aria-invalid={errors.firstName ? "true" : "false"}
					/>
					{errors.firstName?.type === "required" && (
						<label role="alert" className="fieldError">First name field is required</label>
					)}
					{errors.firstName?.type === "maxLength" && (
						<label role="alert" className="fieldError">First name field length must be under 15 carracters.</label>
					)}

					
					<input type="text" id="lastName" name="lastName" placeholder="Last name" 
						{...register("lastName", {required: true, maxLength: 20})}
						aria-invalid={errors.lastName ? "true" : "false"}
					/>
					{errors.lastName?.type === "required" && (
						<label role="alert" className="fieldError">Last name field is required</label>
					)}
					{errors.lastName?.type === "maxLength" && (
						<label role="alert" className="fieldError">Last name field length must be under 20 carracters.</label>
					)}
				</div>
				<div className="separator"></div> {/* Secondary informations */}
				<div className="flex-col">
					<div className="flex-row">
						<input type="number" id="coverts" name="coverts" min="0" placeholder="Coverts" onInput={() => {}} 
							{...register("coverts", {required: true})}
							aria-invalid={errors.coverts ? "true" : "false"}
						/>
						<select id="intExt" name="intExt" {...register("intExt", {required: true})}>
							<option value="int">Interne</option>
							<option value="ext">Externe</option>
						</select>
					</div>
					{errors.coverts?.type === "required" && (
						<label role="alert" className="fieldError">Coverts field is required</label>
					)}
					<input type="text" id="contact" name="contact" placeholder="Contact (e-mail, phone, room...)"
						{...register("contact", {required: true})}
						aria-invalid={errors.contact ? "true" : "false"}
					/>
					{errors.contact?.type === "required" && (
						<label role="alert" className="fieldError">Contact field is required</label>
					)}
				</div>
				<div className="separator"></div> {/* Notes */}
				<div className="flex-col">
					<textarea name="bookingNotes" placeholder="Booking notes" {...register("bookingNotes", {required: false})}></textarea>
					<textarea name="clientNotes" placeholder="Client personnal habits, notes..." {...register("clientNotes", {required: false})}></textarea>
				</div>

				{/* Submit/Reset */}
				<div className="flex-row-around">
					<input type="reset"	onClick={() => clearErrors()} />

					<input type="submit"
						onClick={(event) => {}}
					/>
				</div>

				<input type="hidden" name="id" {...register("id")}/>

				<input type="hidden" {...register("oldCoverts")}/>
				<input type="hidden" {...register("oldDatetime")}/>
			</form>
		</>
	);
}

export default BookingAside;