import React from 'react';
import { useState, useEffect, createRef } from 'react';
import { useFormContext } from "react-hook-form";

// import insertLog from '../../../logs/insertLog';

import { useToast } from '../../hooks/useToast';

import ServicesBox from './assets/servicesBox';

const POSAside = (props) => {
	const title = props.title_POSAside;

	const toast = useToast();

	const {
		getValues,
		setValue,
		register
	} = props.asideMethods;

	const index_POSAside = props.index_POSAside;

	const [serviceBoxSelected, setServiceBoxSelected] = useState(-1);

	const [services, setServices] = useState(getValues(`pos.${index_POSAside}.services`));
	const [coverts, setCoverts] = useState(getValues(`pos.${index_POSAside}.coverts`));

	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');
	const [covertsInput, setCovertsInput] = useState('');
	const [currentServiceIndex, setCurrentServiceIndex] = useState(null);

	useEffect(() => {
		setServiceBoxSelected(-1);
		setStartTime('');
		setEndTime('');
		setCovertsInput('');

		// setServices([]); // Force reload of services to make delete the precedents boxes
		
		setServices(getValues(`pos.${index_POSAside}.services`));
		setCoverts(getValues(`pos.${index_POSAside}.coverts`));
	}, [title]);

	const calculatePos = (time) => {
		time = time.split(':');
		let hourSize = (window.innerHeight * .7 / 24) * time[0] - 3 * (time[0] - 1);
		let minSize = time[1]/60 * (window.innerHeight * .7 / 24);

		return hourSize + minSize;
	}

	const isNewServiceOverlapping = (newService, indexToAvoid = null) => {
		const newTime = newService.split(':');
		const timestamp = newTime[0] * 60 + newTime[1];

		return services.length < 2 ? false : services.some((service, index) => {
			if (index === indexToAvoid) return false;
			if (service === '') return false;


			const time = service.split('-').map(elm => elm.split(':'));
			const timestamp0 = time[0][0] * 60 + time[0][1];
			const timestamp1 = time[1][0] * 60 + time[1][1];

			if (timestamp0 < timestamp && timestamp < timestamp1) return true;
			return false;
		});
	}

	useEffect(() => {
		if (currentServiceIndex === null) return;

		const newServices = [...services];
		newServices[currentServiceIndex] = `${startTime}-${endTime}`;

		const timestamps = newServices[currentServiceIndex].split('-');

		if (isNewServiceOverlapping(timestamps[0], currentServiceIndex)) return toast("warning", "The new service seems to overlap an other one.");
		if (isNewServiceOverlapping(timestamps[1], currentServiceIndex)) return toast("warning", "The new service seems to overlap an other one.");

		if (JSON.stringify(newServices) !== JSON.stringify(services)) {
			setValue(`pos.${index_POSAside}.services`, newServices);
			setServices(newServices); // Mise à jour de services
		}

	}, [startTime, endTime, covertsInput]);

	const addNewService = () => {
		if (isNewServiceOverlapping(`${startTime}`)) return toast("warning", "The new service seems to overlap an other one.");
		if (isNewServiceOverlapping(`${endTime}`)) return toast("warning", "The new service seems to overlap an other one.");
		
		const newServices = [...services, `${startTime}-${endTime}`];

		setValue(`pos.${index_POSAside}.services`, newServices);
		setServices(newServices);

		const newCoverts = [...coverts, covertsInput];

		setValue(
			`pos.${index_POSAside}.coverts`,
			newCoverts
		);
	}

	const deleteService = (index) => {
		if (currentServiceIndex === null) return;

		const newServices = [...services]
		newServices.splice(currentServiceIndex, 1)

		setValue(
			`pos.${index_POSAside}.services`,
			newServices
		);
		setServices(newServices);

		const newCoverts = [...coverts]
		newCoverts.splice(currentServiceIndex, 1);
		setValue(
			`pos.${index_POSAside}.coverts`,
			newCoverts
		);
	}

	// Generate table
	const lines = [];
	const hours = [];

	for (let i = 0; i < 24; i++) {
		lines.push(<div className="services__box"></div>);
		lines.push((i % 6 === 0) ? 
			<div className="services__hour">
				{i.toString().padStart(2, '0')}:00</div> :
			(i === 23 ? <div className="services__hour">23:00</div> : 
				<div className="services__hour"></div>
			)
		);
		lines.push((i % 6 === 0) ? 
			<div className="services__devider" style={{backgroundColor: "var(--light-medium-grey)"}}></div> :
			(i === 23 ? <div className="services__devider" style={{backgroundColor: "var(--light-medium-grey)"}}></div> : 
				<div className="services__devider"></div>
			)
		);
		lines.push(<div className=""></div>);
	}

	const theTrainRails = createRef(null);

	return (
		<>
			<h2 style={{color: "black"}}>POS Selected : {title}</h2>
			<div className="flex-col" onClick={() => {
				setServiceBoxSelected(-1);
				setStartTime('');
				setEndTime('');
				setCovertsInput('');
				setCurrentServiceIndex(null);
			}}>
				<div className="POSAside__services-displayer" ref={theTrainRails}>
					{lines.map((elm) => {
						return elm;
					})}
					{services.map((service, index) => {
						return (
							<ServicesBox
								parent={theTrainRails}

								service={service}
								coverts={coverts}

								setStartTime={setStartTime}
								setEndTime={setEndTime}
								setCovertsInput={setCovertsInput}
								setCurrentServiceIndex={setCurrentServiceIndex}

								setValue={setValue}
								getValues={getValues}

								index={index}
								serviceBoxSelected={serviceBoxSelected}
								setServiceBoxSelected={setServiceBoxSelected}

								key={"ServicesBox-" + index + service}
							/>							
						)
					})}
   					
				</div>
				<div className="flex-col" onClick={(event) => event.stopPropagation()}>
					<div className="flex-col POSAside__services-form">
						<div className="flex-row">
							<p>From :</p>
							<input type="time" value={startTime} 
								onChange={
									(e) => {
										setStartTime(e.target.value);
										if (currentServiceIndex !== null) {
											const newServices = [...services];
											newServices.splice(currentServiceIndex, 1, e.target.value);
											setValue(
												`pos.${index_POSAside}.services`,
												newServices
											);
										}
									}
								}
							/>
							<p>to :</p>
							<input type="time" value={endTime}
								onChange={
									(e) => {
										setEndTime(e.target.value);
										if (currentServiceIndex !== null) {
											const newServices = [...services];
												newServices.splice(currentServiceIndex+1, 1, e.target.value);
											setValue(
												`pos.${index_POSAside}.services`,
												newServices
											);
										}
									}
								}
							/>
						</div>
						<div className="flex-row" style={{marginTop: "10px"}}>
							<p>Coverts :</p>
							<input type="number" value={covertsInput} style={{width: "165px"}}
								onChange={
									(e) => {
										setCovertsInput(e.target.value);
										if (currentServiceIndex !== null) {
											const newCoverts = [...coverts];
											newCoverts.splice(currentServiceIndex, 1, e.target.value);
											setValue(
												`pos.${index_POSAside}.coverts`,
												newCoverts
											);
										}
									}
								}
							/>
						</div>
					</div>
					{ serviceBoxSelected === -1 ? 
						<button className="services-button" style={{width: "75%", margin: "auto"}} onClick={(e) => {
							if (endTime.length === 0) return toast("warning", "The time fields are required.");
							if (covertsInput.length === 0) return toast("warning", "The coverts number field is required.");
							addNewService();
						}}>Add service</button> : 
						<button className="services-button" style={{width: "75%", margin: "auto"}} onClick={(e) => {
							deleteService(currentServiceIndex);
						}}>Delete</button>
					}
					
				</div>
			</div>
		</>
	);
}

export default POSAside;