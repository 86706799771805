import React from 'react';

const Aside = () => {
	return (
		<div className="flex-col-between" style={{height: "100%"}}>
			<div>
				<div className="logo">
					<img src="pictures/logo.svg" alt=""/>
				</div>
				<p>No reservation selected...</p>
			</div>
			<p className="version">v 9.3.0</p>
		</div>
	)
}

export default Aside;
