import React from 'react';
import { useState, useEffect } from 'react';

import { motion, useDragControls } from "framer-motion";

const ServicesBox = (props) => {
	const serviceBoxSelected = props.serviceBoxSelected;
	const setServiceBoxSelected = props.setServiceBoxSelected;

	const service = props.service.split('-');

	const setValue = props.setValue;

	const [rect, setRect] = useState(NaN);
	const [canDisplay, setCanDisplay] = useState(false); // Used to avoid glitching bugs of the Service box

	const convertTimeToPixel = (time) => {
		time = time.split(':');

		const hour = ((time[0]++ + 1) * window.innerHeight * .7 / 25)
		const min  = ((time[1]) * window.innerHeight * .7 / (25 * 60))

		return hour + min + 4;
	}

	const [boxProperties, setBoxProperties] = useState({
		top: NaN,
		width: NaN,
		height: NaN
	});

	useEffect(() => {
		setCanDisplay(false);
		if (service.length < 2) return;
		setRect(props.parent.current?.getBoundingClientRect());
		setBoxProperties({
			top: 
				rect?.top + convertTimeToPixel(service[0]),
			width:
				props.parent.current?.clientWidth - 15,
			height: 
				convertTimeToPixel(service[1]) - convertTimeToPixel(service[0])
		});

		setCanDisplay(true);
	}, [props.parent.current, props.service]);

	return (
		<>
			<motion.div
				// initial={{ opacity: 0 }}
				// animate={{ opacity: 1 }}
				// transition={{
				// 	duration: 0.8,
				// 	delay: 0.5,
				// 	ease: [0, 0.71, 0.2, 1.01]
				// }}
				// exit={{ opacity: 0 }}

				onClick={(event) => {

					props.setCurrentServiceIndex(props.index);

					props.setStartTime(service[0]);
					props.setEndTime(service[1]);

					props.setCovertsInput(props.coverts[props.index]);

					setServiceBoxSelected(props.index);
					event.stopPropagation();
				}}

				style={{
					top: boxProperties.top + props.index * 2.5,
					width: boxProperties.width - 10,
					height: boxProperties.height - 4,
					marginLeft: 5,
					backgroundColor: "rgba(var(--deep-blue), .15)",
					position: "absolute",
					border: "2px solid rgba(var(--deep-blue), .7)",
					color: "rgba(var(--deep-blue), .7)",
					borderRadius: "10px",
					cursor: "pointer",
					overflow: "hidden",
					textAlign: "center",
					lineHeight: (boxProperties.height - 5) + "px",
					boxShadow: serviceBoxSelected == props.index ? "0 0 5px rgba(var(--shadow-select))" : '',
					visibility: canDisplay ? "visible" : "hidden" // To avoid occasionals glitching bugs
				}}
			>
				{service[0]} - {service[1]}
			</motion.div>
		</>
	)
}

export default ServicesBox;