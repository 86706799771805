import React from 'react';

import { useToast } from '../../../hooks/useToast';
import { put_groupState } from '../../../requests/settings/put_groupState';

import Axios from 'axios';

const BookingLine = (props) => {
	const toast = useToast();

	const modify_isAccepted = (isAccepted) => {
		put_groupState(isAccepted, props.id)
		.then((res) => {
			props.setReloadTimes(props.reloadTimes + 1);
			toast("OK", "Booking modify successfuly.");
		})
		.catch(res => toast(res.state, res.value));
	}

	const canUserAcceptRefuseBookings = sessionStorage.getItem("accept_booking") === "true";

	return (
		<div className="flex-row-around booking-line" style={{textAlign: "center"}}>
			<div className="grid-center">
				<p>{props.lastName}<br/>{props.firstName}</p>
			</div>
			<div className="grid-center">
				<p>{props.place}</p>
			</div>
			<div className="grid-center">
				<p>{props.date.split('T')[0].split('-').reverse().join('/')}<br/>{props.time}</p>
			</div>
			<div className="grid-center">
				<p>{props.coverts}</p>
			</div>
			{
				canUserAcceptRefuseBookings &&
				<div className="grid-center booking-line-buttons">
				{
					props.is_accepted === "true"
					? <button onClick={() => modify_isAccepted("false")}><img src="pictures/arrow-up.svg" alt="up"/></button>
					: <button onClick={() => modify_isAccepted("true")}><img src="pictures/arrow-down.svg" alt="down"/></button>
				}
				</div>
			}
		</div>
	);
}

export default BookingLine;