import React from 'react';

import { Reorder, useDragControls } from "framer-motion";

const POSLine = (props) => {
	const item = props.item;
	const index = props.index;

	const getValues = props.getValues;
	const register = props.register;
	const remove = props.remove;
	const controls = useDragControls();

	const displayPOSAside = () => {
		props.setAsideType("pos-services");
		props.setTitle_POSAside(getValues(`pos.${index}.name`));
		props.setIndex_POSAside(index)

		console.log(props.asideType)
	}

	const canUserModifySettings = sessionStorage.getItem("update_pos_settings") === "true";

	return (
		<Reorder.Item 
			value={item}
			id={item.name}
			dragListener={false}
			dragControls={controls}
		>	
			<div className="flex-row pos__line">
				<svg
					xmlns="www.w3.org/2000/svg"
					width="16"
					height="16"
					fill="#ACACAC"
					viewBox="0 0 16 16" 
					onPointerDown={(e) => controls.start(e)}
					style={{ touchAction: "none" }}
				>
					<path d="M1 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zM1 9a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z"/>
				</svg>
				<div className="flex-col">
					<div className="flex-row-between" style={{width: "100%"}}>
						<div className="flex-row">
							<label>
								<input 
									type="checkbox"
									disabled={!canUserModifySettings}
									{...register(`pos.${index}.isOpen`)}
								/>
								<span className="checkbox-custom"></span>
							</label>
							<input
								type="text" 
								placeholder={"POS n° " + index}
								spellCheck="false"
								disabled={!canUserModifySettings}
								{...register(`pos.${index}.name`, {required: true, maxLength: 30})}
							/>
							
						</div>
						<div className="flex-row">
							{
								canUserModifySettings && <>
									<button onClick={(e) => {e.stopPropagation(); e.preventDefault(); displayPOSAside()}}>
										<img src="pictures/three-dots.svg" alt="" title="Modify the POS services' hours" style={{verticalAlign: "middle", paddingRight: "10px"}}/>
										Modify
									</button>
									<button onClick={(e) => {remove(index)}} style={{width: "50px", background: "#FAFAFA"}}>
										<img src="pictures/trash.svg" alt="" title="Delete the POS" style={{verticalAlign: "middle", filter: "contrast(0) brightness(.25)"}}/>
									</button>
								</>
							}
						</div>
						<input type="hidden" {...register(`pos.${index}.services`)}/>
						<input type="hidden" {...register(`pos.${index}.coverts`)}/>
					</div>
				</div>
			</div>
		</Reorder.Item>
	);
}

export default POSLine;