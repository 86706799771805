import Axios from 'axios';

const get_userFamilyRights = () => {
	return new Promise((resolve, reject) => {
		Axios.get(`${process.env.REACT_APP_API_ADRESS}/api/settingsUserRights`, {
			headers: {
				token: sessionStorage.getItem("id")
			}
		})
		.then((res) => {
			return resolve ({
				state: "OK",
				value: res.data.value
			});
		})
		.catch(res => {
			reject({
			state: "error",
			value: "An error occured while trying to load data... Please retry or contact an administrator."
		})});
	});
}

export { get_userFamilyRights };