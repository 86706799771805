import Axios from 'axios';

const put_bookingState = (data) => {
	return new Promise((resolve, reject) => {
		Axios.put(`${process.env.REACT_APP_API_ADRESS}/api/bookings/state`, data, {
			headers: {
				token: sessionStorage.getItem("id")
			}
		})
		.then(res => {			
			return resolve({
				state: "OK",
				value: "User family rights successfuly updated."
			});
			// insertLog("Update POS settings", "NULL", sessionStorage.getItem("id"), "Ok");
		})
		.catch(res => {
			if (res.state === "auth") {
				return reject({
					state: "error",
					value: `You are not connected.`
				});
			}

			return reject({
				state: "error",
				value: "An error occured while updating booking state... Please retry or contact an administrator."
			});
		});
	});
}

export { put_bookingState };