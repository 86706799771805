import React from 'react';
import { useState, useEffect } from 'react';

import Axios from 'axios';

import { get_currentPOS } from '../../requests/settings/get_currentPOS';

import { useToast } from '../../hooks/useToast';

// import insertLog from '../logs/insertLog';

const Searchbar = (props) => {
	const toast = useToast();

	const [posList, setPosList] = useState([]);

	let isSearching 	= props.isSearching;
	let setIsSearching 	= props.setIsSearching;

	let searchInput 	= props.searchInput;
	let setSearchInput 	= props.setSearchInput;

	useEffect(() => {
		get_currentPOS()
		.then((res) => {
			if (res.state !== "OK") toast(res.state, res.value);
			setPosList(res.value);
		});
	}, []);

	return (
		<div id="searchbar" className="flex-row-around">
			<select 
				disabled={(isSearching) ? 'disabled' : ''} 
				key="POS-searchbar"
				onChange={(event) => setSearchInput({...searchInput, pos: event.target.value})}
			>
				<option value="">All POS</option>
				{	posList.length !== 0 &&
					posList.map((object, i) => {
						return <option key={object + '-' + i} value={object}>{object}</option>
					})
				}
			</select>
			<input 
				type="search" 
				placeholder="Research..."
				disabled={(isSearching) ? 'disabled' : null}
				onChange={(event) => setSearchInput({...searchInput, words: event.target.value})}
				onKeyDown={(event) => (event.code === "Enter") ? setIsSearching(true) : null}
				value={searchInput.words}
			/>
			<button 
				disabled={(isSearching) ? 'disabled' : ''}
				onClick={() => setIsSearching(true)}
				title={(isSearching) ? 'Waiting for server response' : 'Research a booking'}>
					{(isSearching) ? <img src="pictures/loadingIcon.svg" alt="loadingIcon"/> : 'Rechercher'}
			</button>
		</div>
	)
}

export default Searchbar;