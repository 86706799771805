import React from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';

import { useToast } from '../../hooks/useToast';

import { connectUser } from '../../requests/logUsers/connectUser';

// import insertLog from '../logs/insertLog';

import './logAccounts.css';

const LogIn = (props) => {
	const navigate = useNavigate();
	const toast = useToast();

	const { 
		register, 
		handleSubmit, 
		formState: { errors },
		setValue, 
	} = useForm();

	const onSubmit = (data) => {
		const connection = connectUser(data);

		connection
		.then((res) => {
			sessionStorage.setItem("id", res.value);
			return navigate("/home");
		})
		.catch(res => toast(res.state, res.value));
	}

	return (
		<>
			<div className="flex-col-around" id="log-in-container">
				<h1>Log in</h1>
				<form className="flex-col-around" onSubmit={handleSubmit(onSubmit)}>
					<input type="text" placeholder="Username" {...register("pseudo", {required: true})} />
					{errors.pseudo && (
						<p className="form-error-message">The username field is required.</p>
					)}
					<input type="password" placeholder="Password" {...register("password", {required: true})}/>
					{errors.password && (
						<p className="form-error-message">The username field is required.</p>
					)}
					<input type="submit"/>
				</form>
			</div>
		</>
	);
}

export default LogIn;