import Axios from 'axios';

const get_bookingsQuantity = () => {
	return new Promise((resolve, reject) => {
		Axios.get(`${process.env.REACT_APP_API_ADRESS}/api/bookings/quantity`, {
			headers: {
				token: sessionStorage.getItem("id")
			}
		})
		.then((res) => {
			// console.info("Booking quantity got successfuly.");
			return resolve({
				state: "OK",
				value: res.data.value[0].quantity
			});
		})
		.catch(err => {
			// insertLog("Get bookings quantity", "NULL", sessionStorage.getItem("id"), "Error", res.data.value);
			return reject({
				state: "error",
				value: "An error occured while getting bookings quantity."
			});
		});
	});
}

export { get_bookingsQuantity };