import Axios from 'axios';

const formatData = (data) => {
	let date = '';
	if (data.setting.periodActivation === 'day') date = ':' + data.setting.day;
	if (data.setting.periodActivation === 'period') date = ':' + data.setting.period0 + '/' + data.setting.period1;

	const dataToSend = {
		headers: {
			token: sessionStorage.getItem("id")
		},
		body: {
			name: data.name,
			groupSize: data.setting.groupSize,
			period: data.setting.periodActivation + date,

			pos: [],
			open: [],
			services: []
		}
	}

	data.pos.forEach((elm) => {
		dataToSend.data.pos.push(elm.name);
		dataToSend.data.open.push(elm.name ? elm.isOpen : '');
		dataToSend.data.services.push(elm.name ? elm.services : '');
	});

	dataToSend.data.pos = dataToSend.data.pos.join(",");
	dataToSend.data.open = dataToSend.data.open.join(",");
	dataToSend.data.services = dataToSend.data.services.join(",");

	return dataToSend;
}

const post_posSettings = (data)  => {
	return new Promise((resolve, reject) => {
		Axios.post(`${process.env.REACT_APP_API_ADRESS}/api/posSettings`, formatData(data))
		.then((res) => {
			return resolve({
				state: "OK",
				value: res.data.value
			});
			// insertLog("Insert POS settings", "NULL", sessionStorage.getItem("id"), "Ok");
		})
		.catch(res => {
			if (res.state === 'IDT') {
				if (res.value === 'groupSize') {
					return reject({
						state: "error",
						value: "Goup size must be a integer greater than 0."
					})
				}
			}
			
			if (res.state === "MD") {
				return reject({
					state: "error",
					value: `The ${res.value} of the setting seems to miss. Please add one.`
				});
			}
			if (res.state === "TSD") {
				return reject({
					state: "error",
					value: `The ${res.value} must be filled.`
				});
			}
			if (res.state === "TLD") {
				return reject({
					state: "error",
					value: `The ${res.value} must be less than 45 characters.`
				});
			}
			if (res.state === "IDT") {
				return reject({
					state: "error",
					value: `The group size must be na integer.`
				});
			}
			if (res.state === "<0") {
				return reject({
					state: "error",
					value: `The group size greater or equal to 1.`
				});
			}

			if (res.state === "auth") {
				return reject({
					state: "error",
					value: `You are not connected.`
				});
			}
		});
	});
}

export { post_posSettings };