import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from "react-router-dom";
import { FormProvider } from "react-hook-form";

import './settings.css';

import POSSettings from './posSettings';
import ConfirmBookings from './confirmBookings';
import UsersRights from './usersRights';
// import Stats from './stats';

// import ErrorBanner from './../globals/errorBanner';

const Settings = (props) => {
	const {
		setAsideType,
		setTitle_POSAside,
		setIndex_POSAside,
		asideMethods
	} = useOutletContext();


	const [areDataSaved, setAreDataSaved] = useState(true);

	const navigate = useNavigate();

	const canUserAccessStats = sessionStorage.getItem("access_statistics") === "true";

	return (
		<>
			<div className="flex-row-between container__small-boxes">
				<div className="settings-box settings-box-small pos-settings">
					<FormProvider {...asideMethods}>
						<POSSettings 
							setAsideType={setAsideType}
							setTitle_POSAside={setTitle_POSAside}
							setIndex_POSAside={setIndex_POSAside}
						>
						</POSSettings>
					</FormProvider>
				</div>
				<div className="settings-box settings-box-small">
					<ConfirmBookings>
						
					</ConfirmBookings>
				</div>
			</div>
			<div className="flex-col" style={{margin: "30px 0"}}>
				<div className="settings-box settings-box-large settings-box__user-rights">
					<UsersRights setAreDataSaved={setAreDataSaved}></UsersRights>
				</div>
				{/*{ !areDataSaved ? 
					<ErrorBanner
						title ="Data unsaved"
						content ="The modifications you just did are still not saved. If the page reloads or if you leave the page, you risk losing them."
					>
					</ErrorBanner> : ''
				}*/}
			</div>
			{
				canUserAccessStats && false &&
				<div className="settings-box settings-box-large">
					{/*<Stats>

					</Stats>*/}
				</div>
			}
			<div className="grid-center" style={{width: "100%"}}>
				<button onClick={() => navigate(`/logs`)} style={{margin: "20px 0 0 0", cursor: "pointer", width: "fit-content"}}>Accede to logs page</button>
			</div>
		</>
	)
}

export default Settings;