import Axios from 'axios';

const get_bookings = (data, meta, offset, searchData) => {
	const canUserSeeBooker = sessionStorage.getItem("see_booker") === "true";

	return new Promise((resolve, reject) => {
		Axios.get(`${process.env.REACT_APP_API_ADRESS}/api/bookings`, {
			headers: {
				token: sessionStorage.getItem("id")
			},
			params: {
				data: data,
				meta: meta || canUserSeeBooker,
				start: 0,
				offset: offset,
				searchData: searchData
			}
		})
		.then((res) => resolve({state: "OK", value: res.data.value}))
		.catch(res => reject({
			state: res.state,
			value: "An error occured, please reload the page or contact an administrator."
		}));
	});
}

export { get_bookings };