import React, { cloneElement, Children } from 'react';

const Context = (props) => {
	const { children, ...restProps } = props;

	const cloneWithProps = (child) => {
		if (!React.isValidElement(child)) return child;
		if (child.type === "div" || child.type === "button") {
			return React.cloneElement(child, {}, 
				React.Children.map(child.props.children, cloneWithProps)
				);
		}

		return React.cloneElement(child, { ...restProps }, 
			React.Children.map(child.props.children, cloneWithProps)
		);
	};

	return React.Children.map(children, cloneWithProps);
}

export { Context };	