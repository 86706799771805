import React, { useState, useEffect, useRef } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { Outlet } from 'react-router-dom';

import { Context } from './modules/context';

// import Logs from './components/logs/logs';

// // Asides
import Aside from './pages/components/aside';
import BookingAside from './pages/components/bookingAside';
import POSAside from './pages/components/posAside';

import Header from './pages/components/header';

const App = () => {
	// Bookings table global hooks
	const [bookingSelected, setBookingSelected] = useState(0);
	const [reloadBookings, setReloadBookings] = useState(0);

	// Aside hooks
	const [asideVisibility, setAsideVisibility] = useState("visible");
	const [asideType, setAsideType] = useState('classic');

	const [title_POSAside, setTitle_POSAside] = useState('');
	const [formInfo_POSAside, setFormInfo_POSAside] = useState([]);
	const [index_POSAside, setIndex_POSAside] = useState();

	const methods = useForm();
	const { register, handleSubmit, formState: { errors }, setValue, setError, getValues, clearErrors } = methods;

	const asideMethods = useForm();
	const { 
		handleSubmit: aside_handleSubmit,
		register: aside_register,
		control: aside_control,
		formState: { errors: aside_errors },
		setValue: aside_setValue,
		getValues: aside_getValues,
		watch: aside_watch,
	} = asideMethods;

	const handleResize = (event) => {
		if (window.innerWidth < 950) {
			setAsideVisibility("hidden");
		} else if (asideVisibility === "visible") {
			setAsideVisibility("visible")
		}
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize);
    	return () => window.removeEventListener('resize', handleResize);
	}, []);

	const container = useRef();

	return (
		<Context 
			asideVisibility={asideVisibility}
			setAsideVisibility={setAsideVisibility}

			asideType={asideType} 
			setAsideType={setAsideType}

			reloadBookings={reloadBookings}
			setReloadBookings={setReloadBookings}
		>
			<Header
				setBookingSelected={setBookingSelected}
			/>
			<FormProvider {...methods}>
				<div className="flex-row-around">
					<div id="aside" className="flex-col" style={{visibility: asideVisibility, display: asideVisibility === "visible" ? "block" : "none", overflowY: "auto"}}>
						{(asideType === 'classic' || !(sessionStorage.getItem("update_bookings") === "true")) ? <Aside /> : ''}
						{
							(asideType === 'ADD' && sessionStorage.getItem("insert_bookings") === "true") || 
							(asideType === 'MOD' && sessionStorage.getItem("update_bookings") === "true") ? (<BookingAside setBookingSelected={setBookingSelected} />) : null
						}
						{
							(asideType === 'pos-services') ? (
								<POSAside
									title_POSAside={title_POSAside}
									formInfo_POSAside={formInfo_POSAside}
									index_POSAside={index_POSAside}
									asideMethods={asideMethods}
								></POSAside>
							) : null
						}
					</div>
					<div
						id="container"
						onClick={() => setBookingSelected(0) & setAsideType("classic")}
						ref={container}

						style={{
							minWidth: asideVisibility === "visible" ? "calc(100vw - 400px)" : "calc(100vw - 60px)",
							maxWidth: asideVisibility === "visible" ? "calc(100vw - 200px)" : "calc(100vw - 60px)",
							width: asideVisibility === "visible" ? "75vw" : "calc(100vw - 60px)"
						}}
					>
						<Outlet context={{
							container: container,
							
							asideVisibility: asideVisibility, 
							setAsideVisibility: setAsideVisibility,
							
							setAsideType: setAsideType,
							asideType: asideType,

							setTitle_POSAside: setTitle_POSAside,
							setIndex_POSAside: setIndex_POSAside,

							reloadBookings: reloadBookings, 
							setReloadBookings: setReloadBookings,

							setBookingSelected: setBookingSelected,
							bookingSelected: bookingSelected,

							asideMethods: asideMethods
						}}/>
					</div>
				</div>
			</FormProvider>
		</Context>
 	);
}

export default App;

// // FRONT-END APP : 2300