import Axios from 'axios';

import { getRights } from './getRights'

const connectUser = (data) => {
	console.log(data)
	if (process.env.REACT_APP_DISPLAY_LOGS_3) console.log("[Connect user]: called.");

	return new Promise((resolve, reject) => {
		Axios.post(`${process.env.REACT_APP_API_ADRESS}/api/users/connect`, {
			pseudo: data.pseudo,
			password: data.password
		})
		.then((res) => {
			if (process.env.REACT_APP_DISPLAY_LOGS_2) console.log("[Connect user]: success ->", res.data.value);

			const userID = res.data.value[0].id;

			if (userID) {
				getRights(userID)
				.then(res => {
					sessionStorage.setItem("pseudo", data.pseudo);

					return resolve({
						state: "OK", 
						value: userID // Supposed token
					});
				})
				.catch(res => {
					console.log(res)
					return reject({
						state: "error",
						value: "An error occured while trying to connect you... Please retry or contact an administrator."
					});
				});
			}
		})
		.catch(error => {
			if (process.env.REACT_APP_DISPLAY_LOGS_0) console.log("[Connect user]: error ->", error);
			
			if (error.response) {
				if (error.response.status === 422) {
					if (error.response.data.state === "MD") {
						return reject({
							state: "warning",
							value: "One or more fields seem not fully filled. If it's the case, please contact an administartor."
						});
					}
				}
				if (error.response.status === 401) {
					return reject({
						state: "warning",
						value: "Values in field seem uncorrect."
					});
				}
			}

			return reject({
				state: "error",
				value: "An error occured while trying to connect you... Please retry or contact an administrator."
			});
		});
	});
}

export { connectUser };