import { useState, useEffect } from 'react';

import Axios from 'axios';

import './logs.css';

import LogRow from './logRow';

const Logs = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [logs, setLogs] = useState([]);

	const [offset, setOffset] = useState(150);

	const toast = props.toast;

	useEffect(() => {
		const getLogs = () => {
			setIsLoading(true);

			Axios.get(`${process.env.REACT_APP_API_ADRESS}/api/logs`, {
				params: {
					data: {
						start: 0,
						offset: offset
					}, 
					id: sessionStorage.getItem("id")
				}
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.state === "failed") return toast("error", "An error occured while getting logs.");

				setLogs(res.data.value);
			});
		}

		getLogs();
	}, []);

	return (
		<>
			<h1>F&B-Database Logs</h1>
			{
				!isLoading && logs !== [] ? (
					logs.map((object, index) => {
						return (
							<LogRow
								operation={object.operation}
								affectedRow={object.affected_row}
								user={object.pseudo}
								date={object.date}
								state={object.state}
								error={object.error}

								key={object.id}
							></LogRow>
						);
					})
				) : (
					<div className="grid-center loader">
						<div>
							<img src="pictures/loadingIcon.svg" alt="loader"/>
							<h4>Loading bookings...</h4>
						</div>
					</div>
				)
			}
		</>
	)
}

export default Logs;