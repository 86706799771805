import React from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';

import { postUser } from '../../requests/logUsers/postUser';

import { useToast } from '../../hooks/useToast';

// import insertLog from '../logs/insertLog';

import './logAccounts.css';

const LogUp = (props) => {
	const navigate = useNavigate();

	const toast = useToast();

	const { 
		register, 
		handleSubmit, 
		formState: { errors },
		setValue, 
		// setError, 
		getValues, 
		// clearErrors 
	} = useForm();

	const onSubmit = (data) => {
		postUser(data)
		.then(res => {
			toast(res.state, res.value);
			return navigate("/settings");
		})
		.catch(res => toast(res.state, res.value));
	}

	return (
		<>
			<div className="flex-col-around" id="log-in-container">
				<h1>Add user up</h1>
				<form className="flex-col-around" onSubmit={handleSubmit(onSubmit)}>
					<input type="text" placeholder="Username" {...register("pseudo", {required: true, maxLength: 45})} />
					{errors.pseudo && errors.pseudo?.type === 'required' && (
						<p className="form-error-message">The username field is required.</p>
					)}
					{errors.pseudo && errors.pseudo?.type === 'maxLength' && (
						<p className="form-error-message">The username field must be less than 45 characters.</p>
					)}
					<input type="password" placeholder="Password" {...register("password", {required: true})}/>
					{errors.password && (
						<p className="form-error-message">The password field is required.</p>
					)}
					<input type="password" placeholder="Password verification" {...register("password2", {required: true})}/>
					{errors.password2 && (
						<p className="form-error-message">The password verification field is required.</p>
					)}
					<select name="role" style={{margin: "auto", width: "calc(70% + 8px)"}} {...register("role", {requires: true})} defaultValue="role">
						<option value="role" disabled>Select a family user</option>
						<option value="employee">Employee</option>
						<option value="executive">Manager</option>
						<option value="admin">Administrator</option>
					</select>
					{errors.role && (
						<p className="form-error-message">The role field is required.</p>
					)}
					<input type="submit"/>
				</form>
			</div>
		</>
	);
}

export default LogUp;