import { useState } from 'react';

const LogRow = (props) => {
	const operation 	= props.operation;
	const affectedRow 	= props.affectedRow;
	const user 			= props.user;
	const date 			= props.date.split('T')[0].split('-').reverse().join('/');
	const time 			= props.date.split('T')[1].slice(0, 8);
	const state 		= props.state;
	const error 		= props.error;

	return (
		<>
			<div className="flex-row log-row" style={{
				border: state === "OK" && "2px solid #00C800" ||
						state === "Warning" && "2px solid darkorange" ||
						state === "Error" && "2px solid red" ||
						state === "Info" && "2px solid #60C8FF"
			}}>
				<div className="grid-center" style={{width: "70px", height: "70px", margin: "auto 10px"}}>
					{
						state === "OK" ? <img src="pictures/logs/validIcon.svg" alt="Valid"/> :
						state === "Warning" ? <img src="pictures/logs/warnIcon.svg" alt="Warning"/> :
						state === "Error" ? <img src="pictures/logs/errorIcon.svg" alt="Error"/> :
						state === "Info" ? <img src="pictures/logs/infoIcon.svg" alt="Info"/> :
						null
					}
				</div>
				<div className="flex-row-around log-row-values" style={{width: "calc(100% - 70px)", margin: "auto"}}>
					<div className="logRow_operation">
						<p>{operation}</p>
					</div>
					<div className="logRow_rowsAffected">
						<p>{affectedRow !== null ? affectedRow : '---'}</p>
					</div>
					<div className="logRow_user">
						<p>{user}</p>
					</div>
					<div className="logRow_date" style={{lineHeight: "initial"}}>
						<p>{date}<br/>{time}</p>
					</div>
					{/*<div className="logRow_state">
						<p>{state}</p>
					</div>*/}
					{/*<div className="logRow_rowsAffected">
						<p>{error}</p>
					</div>*/}
				</div>
			</div>
		</>
	)
}

export default LogRow;